import { getAutoHeightDuration } from '@/utils/getAutoHeightDuration';

const reduceMotion = () => {
  return (
    'matchMedia' in window &&
    window.matchMedia('(prefers-reduced-motion)').matches
  );
};

export function scrollToSmoothly({
  position,
  offset = 0,
}: {
  position: number;
  offset?: number;
}) {
  const scrollPosition = position + offset;

  if ('scrollBehavior' in document.documentElement.style) {
    return window.scroll({ top: scrollPosition, behavior: 'smooth' });
  }
  if (reduceMotion()) {
    return window.scrollTo(0, scrollPosition);
  }

  const currentPos = window.scrollY;
  const height = Math.abs(currentPos - scrollPosition);

  let start: number | null = null;
  const animationTime = getAutoHeightDuration(height);

  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    const progress = currentTime - start;
    if (currentPos < scrollPosition) {
      window.scrollTo(
        0,
        ((scrollPosition - currentPos) * progress) / animationTime + currentPos
      );
    } else {
      window.scrollTo(
        0,
        currentPos - ((currentPos - scrollPosition) * progress) / animationTime
      );
    }
    if (progress < animationTime) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, scrollPosition);
    }
  });
}
