import * as React from 'react';
export function IconSpecialLabel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 41"
      aria-hidden={true}
      {...props}
    >
      <path d="M0 0h128.75L140 20.5 128.75 41H0V0z" />
    </svg>
  );
}
