import cls from 'clsx';
import React from 'react';
import { Span } from '@/components/atoms/Typography/Typography';
import { IconSpecialLabel } from '@/components/Icons/IconSpecialLabel';
import styles from './SpecialOfferLabel.module.scss';

export interface SpecialOfferLabelProps {
  className?: string;
  text: string;
  color?: 'primary' | 'secondary' | 'lightBlue';
  version?: 'body1' | 'body2';
}

export const SpecialOfferLabel = ({
  className,
  text,
  color = 'primary',
  version = 'body1',
}: SpecialOfferLabelProps) => (
  <span
    className={cls(styles.container, className, {
      [styles.secondary]: color === 'secondary',
      [styles.lightBlue]: color === 'lightBlue',
    })}
    aria-hidden="true"
  >
    <Span
      className={cls(styles.label, [styles[version]])}
      color="white"
      version={version}
    >
      {text}
    </Span>
    <IconSpecialLabel className={styles.svg} />
  </span>
);
