import { product, ProductKeys } from '@/data/productsConfig';
import { Countries, countries } from '@/types/Countries';

export const testRideHomeBanner: Partial<
  Record<
    Countries,
    | { cityCode: string; bike: ProductKeys }
    | { cityCodes: string[]; bike: ProductKeys }
  >
> = {
  [countries.DE]: { cityCodes: ['berlin', 'hamburg'], bike: 'power-7' },
};

export type TestRideLocation = {
  owner: number;
  appointmentType?: number;
  location?: string;
  address?: string;
};

type BikeLocations = Partial<
  Record<ProductKeys, Record<string, TestRideLocation[]>>
>;

export const testRideBikes: Partial<Record<Countries, BikeLocations>> = {
  [countries.NL]: {
    [product.power7]: {
      utrecht: [{ appointmentType: 27872899, owner: 24259288 }],
      amsterdam: [
        {
          appointmentType: 43058950,
          owner: 24259288,
          location: 'Swapfiets Amsterdam Zuid - Ceintuurbaan',
          address: 'Ceintuurbaan 11-13, 1072 GE Amsterdam',
        },
        {
          appointmentType: 29939451,
          owner: 24259288,
          location: 'Swapfiets Amsterdam Zuid - Stadionplein',
          address: 'Stadionplein 26, 1076 CM Amsterdam',
        },
        {
          appointmentType: 39021279,
          owner: 24259288,
          location: 'Swapfiets Amsterdam West',
          address: 'Bilderdijkstraat 57H, 1053 KL Amsterdam',
        },
        {
          appointmentType: 27872903,
          owner: 24259288,
          location: 'Swapfiets Amsterdam Oost',
          address: 'Roetersstraat 17, 1018 WB Amsterdam',
        },
      ],
      groningen: [{ appointmentType: 38280588, owner: 24259288 }],
      haarlem: [{ appointmentType: 38853720, owner: 24259288 }],
      eindhoven: [{ appointmentType: 38282704, owner: 24259288 }],
      rotterdam: [{ appointmentType: 29905178, owner: 24259288 }],
      'the-hague': [{ appointmentType: 38282688, owner: 24259288 }],
      leiden: [{ appointmentType: 41819774, owner: 24259288 }],
      tilburg: [{ appointmentType: 41819979, owner: 24259288 }],
      arnhem: [{ appointmentType: 41820336, owner: 24259288 }],
      delft: [{ appointmentType: 41820061, owner: 24259288 }],
      nijmegen: [{ appointmentType: 41820172, owner: 24259288 }],
      maastricht: [{ appointmentType: 41820185, owner: 24259288 }],
      enschede: [{ appointmentType: 41820200, owner: 24259288 }],
      leeuwarden: [{ appointmentType: 41820216, owner: 24259288 }],
      breda: [{ appointmentType: 41820231, owner: 24259288 }],
    },
    [product.power1]: {
      groningen: [{ appointmentType: 38280660, owner: 24259288 }],
      haarlem: [{ appointmentType: 38853728, owner: 24259288 }],
      eindhoven: [{ appointmentType: 38282715, owner: 24259288 }],
      rotterdam: [{ appointmentType: 38280412, owner: 24259288 }],
      'the-hague': [{ appointmentType: 38282694, owner: 24259288 }],
      utrecht: [{ appointmentType: 27872893, owner: 24259288 }],
      amsterdam: [
        {
          appointmentType: 43058941,
          owner: 24259288,
          location: 'Swapfiets Amsterdam Zuid - Ceintuurbaan',
          address: 'Ceintuurbaan 11-13, 1072 GE Amsterdam',
        },
        {
          appointmentType: 31595340,
          owner: 24259288,
          location: 'Swapfiets Amsterdam Zuid - Stadionplein',
          address: 'Stadionplein 26, 1076 CM Amsterdam',
        },
        {
          appointmentType: 38990056,
          owner: 24259288,
          location: 'Swapfiets Amsterdam West',
          address: 'Bilderdijkstraat 57H, 1053 KL Amsterdam',
        },
        {
          appointmentType: 27105217,
          owner: 24259288,
          location: 'Swapfiets Amsterdam Oost',
          address: 'Roetersstraat 17, 1018 WB Amsterdam',
        },
      ],
      leiden: [{ appointmentType: 41819757, owner: 24259288 }],
      tilburg: [{ appointmentType: 41819976, owner: 24259288 }],
      arnhem: [{ appointmentType: 41819990, owner: 24259288 }],
      delft: [{ appointmentType: 41820057, owner: 24259288 }],
      nijmegen: [{ appointmentType: 41820171, owner: 24259288 }],
      maastricht: [{ appointmentType: 41820182, owner: 24259288 }],
      enschede: [{ appointmentType: 41820193, owner: 24259288 }],
      leeuwarden: [{ appointmentType: 41820214, owner: 24259288 }],
      breda: [{ appointmentType: 41820222, owner: 24259288 }],
    },
  },
  [countries.BE]: {
    [product.power7]: {
      brussels: [{ appointmentType: 29905443, owner: 25132806 }],
      antwerp: [{ appointmentType: 38075485, owner: 25132806 }],
      ghent: [{ appointmentType: 38076002, owner: 25132806 }],
      leuven: [{ appointmentType: 41824374, owner: 25132806 }],
    },
    [product.power1]: {
      brussels: [{ appointmentType: 37247209, owner: 25132806 }],
      antwerp: [{ appointmentType: 38075509, owner: 25132806 }],
      ghent: [{ appointmentType: 38075999, owner: 25132806 }],
      leuven: [{ appointmentType: 41820505, owner: 25132806 }],
    },
  },
  [countries.DE]: {
    [product.power7]: {
      cologne: [{ appointmentType: 38080456, owner: 25429681 }],
      berlin: [
        {
          appointmentType: 38080700,
          owner: 25429681,
          location: 'Swapfiets Berlin - Fernsehturm',
          address: 'Karl-Liebknecht-Str. 13',
        },
      ],
      hamburg: [{ appointmentType: 31093287, owner: 25429681 }],
      munster: [{ appointmentType: 43055491, owner: 25429681 }],
      oldenburg: [{ appointmentType: 43055501, owner: 25429681 }],
      hannover: [{ appointmentType: 43055524, owner: 25429681 }],
      bremen: [{ appointmentType: 43055533, owner: 25429681 }],
      bonn: [{ appointmentType: 43055545, owner: 25429681 }],
      dusseldorf: [{ appointmentType: 43055561, owner: 25429681 }],
      'frankfurt-am-main': [{ appointmentType: 43055570, owner: 25429681 }],
      leipzig: [{ appointmentType: 43055583, owner: 25429681 }],
      kiel: [{ appointmentType: 43055595, owner: 25429681 }],
      brunswick: [{ appointmentType: 43055608, owner: 25429681 }],
      gottingen: [{ appointmentType: 43055617, owner: 25429681 }],
      DE: [{ owner: 25429681 }],
    },
    [product.power1]: {
      cologne: [{ appointmentType: 38080445, owner: 25429681 }],
      berlin: [
        {
          appointmentType: 38080721,
          owner: 25429681,
          location: 'Swapfiets Berlin - Fernsehturm',
          address: 'Karl-Liebknecht-Str. 13',
        },
      ],
      hamburg: [{ appointmentType: 37247593, owner: 25429681 }],
      munster: [{ appointmentType: 43055489, owner: 25429681 }],
      oldenburg: [{ appointmentType: 43055496, owner: 25429681 }],
      hannover: [{ appointmentType: 43055518, owner: 25429681 }],
      bremen: [{ appointmentType: 43055528, owner: 25429681 }],
      bonn: [{ appointmentType: 43055541, owner: 25429681 }],
      dusseldorf: [{ appointmentType: 43055553, owner: 25429681 }],
      'frankfurt-am-main': [{ appointmentType: 43055567, owner: 25429681 }],
      leipzig: [{ appointmentType: 43055573, owner: 25429681 }],
      kiel: [{ appointmentType: 43055589, owner: 25429681 }],
      brunswick: [{ appointmentType: 43055602, owner: 25429681 }],
      gottingen: [{ appointmentType: 43055612, owner: 25429681 }],
      DE: [{ owner: 25429681 }],
    },
  },
  [countries.DK]: {
    [product.power7]: {
      copenhagen: [{ appointmentType: 39028320, owner: 25564225 }],
      odense: [{ appointmentType: 43057325, owner: 25564225 }],
      aarhus: [{ appointmentType: 43057342, owner: 25564225 }],
      aalborg: [{ appointmentType: 43057362, owner: 25564225 }],
    },
    [product.power1]: {
      copenhagen: [{ appointmentType: 39028301, owner: 25564225 }],
      odense: [{ appointmentType: 43057323, owner: 25564225 }],
      aarhus: [{ appointmentType: 43057330, owner: 25564225 }],
      aalborg: [{ appointmentType: 43057356, owner: 25564225 }],
    },
  },
  [countries.ES]: {
    [product.power7]: {
      barcelona: [{ appointmentType: 43476115, owner: 28534276 }],
    },
    [product.power1]: {
      barcelona: [{ appointmentType: 41909614, owner: 28534276 }],
    },
    [product.originalPlus]: {
      barcelona: [{ appointmentType: 56695971, owner: 28534276 }],
    },
    [product.deluxe7]: {
      barcelona: [{ appointmentType: 56695974, owner: 28534276 }],
    },
  },
  [countries.AT]: {
    [product.power7]: {
      vienna: [{ appointmentType: 43057520, owner: 25429681 }],
    },
    [product.power1]: {
      vienna: [{ appointmentType: 43057515, owner: 25429681 }],
    },
    [product.originalPlus]: {
      vienna: [{ appointmentType: 57460682, owner: 25429681 }],
    },
    [product.deluxe7]: {
      vienna: [{ appointmentType: 57460686, owner: 25429681 }],
    },
  },
  [countries.FR]: {
    [product.power7]: {
      paris: [{ appointmentType: 37243712, owner: 25660795 }],
    },
    [product.power1]: {
      paris: [{ appointmentType: 31731801, owner: 25660795 }],
    },
    [product.originalPlus]: {
      paris: [{ appointmentType: 57460771, owner: 25660795 }],
    },
    [product.deluxe7]: {
      paris: [{ appointmentType: 57460773, owner: 25660795 }],
    },
  },
  [countries.GB]: {
    [product.power7]: {
      london: [{ appointmentType: 36773888, owner: 27097202 }],
    },
    [product.power1]: {
      london: [{ appointmentType: 36773687, owner: 27097202 }],
    },
    [product.originalPlus]: {
      london: [{ appointmentType: 57460732, owner: 27097202 }],
    },
    [product.deluxe7]: {
      london: [{ appointmentType: 57460735, owner: 27097202 }],
    },
  },
};

export type TestRideBikes = keyof BikeLocations;

export const testRideLandingMap: Partial<Record<string, string>> = {
  'nl-NL': '5473d9e9-195b-429f-95ad-0c02cab9ce54?share=true',
  'en-NL': '1c70a2aa-3abf-4ca6-a3cb-469aab007ad5?share=true',
  'da-DK': '820ad271-60ab-442d-a33e-0398bbf440bf?share=true',
  'en-DK': '627840c9-e561-470b-b30a-44b7912cfc13?share=true',
  'en-GB': 'b1836939-8586-41ec-86df-350e0b999357?share=true',
  'fr-FR': '0fd7ad4e-1216-456d-adaa-681b66f785c0?share=true',
  'en-FR': 'a545e383-cfa2-4f6b-b7e5-77b7d9d54da4?share=true',
  'nl-BE': 'ee458ce2-503b-465e-ad26-ea4dea5a4464?share=true',
  'fr-BE': '79f66373-e3ce-4b10-bdf2-2ebbba3a863d?share=true',
  'en-BE': 'aa09f21e-33fd-4d67-a63f-6c5e436d67c6?share=true',
  'de-DE': '8d02dfcd-44da-4347-8e44-9851150ce113?share=true',
  'en-DE': 'f6bea502-97ae-4369-806c-0e185f60215e?share=true',
  'de-AT': '79afe8d6-f32b-4771-a85f-514bd89d6515?share=true',
  'en-AT': '24d62a77-b315-4d3b-983a-4f7f78feceaf?share=true',
  'es-ES': 'd2bf230d-02b5-4ebf-95bf-5562f7839752?share=true',
  'en-ES': 'ea24a3a7-ea7c-46e1-a971-84426d8cb3c3?share=true',
};

export const supportedLocales = Object.keys(testRideLandingMap) as string[];
