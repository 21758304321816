import cls from 'clsx';
import React from 'react';
import styles from './TextAndImage.module.scss';

export interface TextAndImageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  left: React.ReactNode | React.ReactNode[];
  className?: string;
  classLeft?: string;
  right: React.ReactNode | React.ReactNode[];
  classRight?: string;
  wrapOnTop?: 'left' | 'right';
  as?: 'section' | 'div';
}

export const TextAndImage = ({
  left,
  right,
  classLeft,
  classRight,
  className,
  wrapOnTop = 'left',
  as = 'section',
  ...rest
}: TextAndImageProps) => {
  const Component = as;

  return (
    <Component
      className={cls(styles.container, styles[wrapOnTop], className)}
      {...rest}
    >
      <div className={cls(styles.leftSide, classLeft)}>{left}</div>
      <div className={cls(styles.rightSide, classRight)}>{right}</div>
    </Component>
  );
};
