export const getPromoLabelTranslationKey = (
  productTranslationKey: string,
  hasReducedPrice: boolean,
  hasNoSignUpFee: boolean,
  isPowerPlusProduct?: boolean
) => {
  if (hasReducedPrice) {
    return 'common:specialOffers.priceReduction';
  }

  if (hasNoSignUpFee) {
    return 'common:specialOffers.noSignupFee';
  }

  return `product:${productTranslationKey}.${
    isPowerPlusProduct ? 'purposeOfUse' : 'specialOffer'
  }.label`;
};
